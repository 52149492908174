@import '../../styles/app';

.sidesWrapper {
  display: flex;
  flex-wrap: wrap;
}

.analyticsSide {
  width: 70%;

  &:last-child {
    width: 30%;
    padding-left: $grid-gutter-width;
  }

  @media (max-width: 1700px) {
    width: 100% !important;

    &:last-child {
      padding-left: 0;
    }
  }

  @media (min-width: 1200px) and (max-width: 1650px) {
    .lastSideElement {
      max-width: 50%;
    }
  }

  @media (min-width: 1650px) and (max-width: 1920px) {
    .lastSideElement {
      max-width: 1 / 3 * 100%;
    }
  }
}